export default [
  {
    text: 'ID',
    align: 'left',
    sortable: true,
    value: 'id',
    width: '20px'
  },
  {
    text: 'Заголовок Н1',
    align: 'left',
    sortable: true,
    value: 'header',
    width: '70px'
  },
  {
    text: 'Опубликовано',
    align: 'left',
    sortable: true,
    value: 'is_published',
    width: '40px'
  },
  {
    text: 'Alias',
    align: 'left',
    sortable: true,
    value: 'url',
    width: '80px'
  },
  {
    text: 'Мета заголовок',
    align: 'left',
    sortable: false,
    value: 'title',
    width: '120px'
  },
  {
    text: 'Мета описание',
    align: 'left',
    sortable: true,
    value: 'meta_description',
    width: '110px'
  },
  {
    text: 'Создано',
    align: 'left',
    sortable: true,
    value: 'created_at',
    width: '80px'
  },
  {
    text: 'Касание',
    align: 'left',
    sortable: true,
    value: 'updated_at',
    width: '80px'
  }
]
