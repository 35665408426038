<template>
  <div
    v-scroll="handleScrollPosition"
  >
    <div class="d-flex align-center">
      <v-btn
        small
        class="primary ml-3 mt-3"
        @click="$emit('detail', {id: null})"
      >
        <v-icon
          class="mr-3"
        >mdi-plus
        </v-icon>
        Добавить страницу
      </v-btn>
    </div>
    <Table
      :options="value.requestParams.options"
      @input="localRequestParams.options = $event"
      :loading="loadingTable"
      :items="items"
      :items-length="itemsLength"
      @detail="$emit('detail', $event)"
    />
  </div>
</template>

<script>
import Table from './List/Table'
import list from '@/components/mixins/list'

export default {
  mixins: [list],
  name: 'List',
  components: { Table },
  data () {
    return {
      fillTableUrl: '/cms-page'
    }
  }
}
</script>

<style scoped>

</style>
