<template>
  <v-container fluid>
    <v-row class="text-left">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :options="options"
          @update:options="$emit('input', $event)"
          :server-items-length="itemsLength"
          :loading="loading"
          class="elevation-1"
          multi-sort
          @click:row="detail($event.id)"
        >
          <template v-slot:item.created_at="{ item }">
            <div>{{item.created_at ? formatTodayDate(item.created_at) : ''}}</div>
          </template>
          <template v-slot:item.updated_at="{ item }">
            <div>{{item.updated_at ? formatTodayDate(item.updated_at) : ''}}</div>
          </template>
          <template v-slot:item.is_published="{ item }">
            <div>{{item.is_published ? 'Да' : 'Нет'}}</div>
          </template>
          </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import headers from './header'
import formatters from '@/components/mixins/formatters'
import table from '@/components/mixins/table'

export default {
  mixins: [formatters, table],
  name: 'Table',
  data: () => ({
    headers: headers
  })
}
</script>

<style scoped>

</style>
